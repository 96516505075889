import { Component, EventEmitter, Output, input, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-select-box',
    standalone: true,
    imports: [FormsModule],
    template: `
        <select [ngModel]="selectedValue()" (change)="valueChanged($event)">
            <option value="" hidden>{{ placeholder() }}</option>
            @if (showAllOption()) {
            <option value="all">All</option>
            } @for(item of items(); track item[valueExpr()]) {
            <option [value]="item[valueExpr()]">{{ item[displayExpr()] }}</option>
            }
        </select>
    `,
    styles: ``,
})
export class SelectBoxComponent {
    items = input.required<any[]>();
    valueExpr = input<string>('id');
    displayExpr = input<string>('name');
    placeholder = input<string>('select');
    showAllOption = input<boolean>(true);
    selectedValue = input<any>('');
    @Output() onValueChanged = new EventEmitter<any>();

    valueChanged(e: any) {
        this.onValueChanged.emit(e);
    }
}
